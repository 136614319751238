/* This file configures all built-in datasets, and the datasets menu.

The Datasets object comprises a set of "dataset collections". Each
collection is a property whose name appears in the datasets menu. The
property value is an array of the dataset objects in that collection.
Each collection is shown as a set of tabs, with one dataset per tab.

Collections and datasets appear in the order that they are listed here.
On startup, the app auto-loads the first collection and auto-selects
the first dataset in that collection.

To add a new dataset (or collection), first put its data in a suitable
place in the source code, then add an entry for it to the Datasets array,
following the patterns of a similar existing datasets.

The dataFile property of a dataset object is required. It contains most
or all of the data that is displayed to the user. All other properties are
optional -- and largely self-explanatory, in context -- but two properties
deserve special mention: label and id.

- If the label property is present, it appears in the datasets menu.
- If there is no label but there is an id, the id appears in the menu.
- The id, if present, must be unique among all datasets. To show the
  dataset via URL, put the id into the "d=" parameter of the URL.
  If the id is not present, the dataset is not accessible via URL.
*/

//////////////////////////////////////////////////////////////////////////

// Every dataset needs a CSV file.

// NOTE: Avoid characters other than '-' and '_' in filenames. Some
// special characters (e.g. '+') cause permission errors in the release
// environment. See https://jira.autodesk.com/browse/PRISM-31 for more info.

import sf from "../data-sf-onemarket/sf-onemarket.csv";
import pdx from "../data-pdx/pdx.csv";

import personas from "../data-personas/personas.csv";
import prod from "../data-products/products.csv";
//import alex from "../data-alex/misc/alex.csv";
import alex_adsk from "../data-alex/GLOSSADSK.txt";
import alex_industry from "../data-alex/GLOSSINDUSTRY.txt";
import alex_tad from "../data-alex/GLOSSTAD.txt";
import tips from "../data-tips/Submit Your Tip.csv";
import applause from "../data-applause/Applause Gift Cards.csv";

//import all from "../data-all-research/All Research.csv";
import octo from "../data-octo/Research Paper Search.csv";
import dxc from "../data-dxc/DXC XD Research Projects.csv";
import pset from "../data-pset/PSET XD Research Projects.csv";
import psetJson from "../data-json/pset.json";
import acs from "../data-acs-research/ACS SiteCon User Research Index.csv";
import spot from "../data-spot/ACS Research Spotlight.csv";
import acs_dt from "../data-acs-research/ACS Dovetail.csv";
import pdms from "../data-pdms/PDMS Research Projects.csv";
import aex from "../data-aex/export.csv";
import au from "../data-au-research/AU Research Roster.csv";
import au_impact from "../data-au-research/AU Impact Cards.csv";
import hive from "../data-hive/hive.csv";
import hive_old from "../data-hive/hive-old.csv";
import ds_e from "../data-ds/DS Experiments.csv";
import cxa from "../data-cxa/Currently Supported Dashboards.csv";
import acad from "../data-acad/AutoCAD User Research.csv";

import cso from "../data-cso/cso.csv"; // getting close; needs an accumulator page
import wwm from "../data-wwm/wwm.csv"; // getting close; needs an accumulator page
import ems from "../data-ems/EMS UX Research Catalog 2007-2021.csv"; // needs substantial work

import chi from "../data-ext/CHI 2018 Papers.csv";
import bots from "../data-ext/Social Machines.csv";
import expres from "../data-ext/Experience Research.csv";
import miscres from "../data-ext/External Misc.csv"; // currently In Development
import bias from "../data-bias/export.csv"; // has big Images folder

import dial from "../data-geo/country_codes_dialing_A2_A3.csv";
import dial2 from "../data-geo/country_codes_dialing_A2.csv";
import dial3 from "../data-geo/country_codes_dialing_A3.csv";
// import dialall from "../data-geo/country_codes_dialing_A2_A3_M49.csv"; // avoid clutter until we need this

import dmf_rwx from "../data-dmf/Rosetta_decision_making.csv";
import dmf_acs from "../data-dmf/ACS XA Decision Log.csv";

import test_basic_loading from "../data-test/test_basic_loading.csv";
import test_basic_formatting from "../data-test/test_basic_formatting.csv";
import test_basic_linking from "../data-test/test_basic_linking.csv";
import test_basic_headers from "../data-test/test_basic_headers.csv";

import test_specialFormatting_default from "../data-test/test_specialFormatting_default.csv";
import test_specialFormatting_multi from "../data-test/test_specialFormatting_multi.csv";
import test_specialFormatting_none from "../data-test/test_specialFormatting_none.csv";
import test_specialFormatting_override from "../data-test/test_specialFormatting_override.csv";
import test_specialFormatting_suppress from "../data-test/test_specialFormatting_suppress.csv";
import test_specialFormatting_avatars from "../data-test/test_specialFormatting_avatars.csv";
import test_specialFormatting_blanks from "../data-test/test_specialFormatting_blanks.csv";

import test_removeHeaderEndings from "../data-test/test_removeHeaderEndings.csv";
import test_removeHeaderEndings_order from "../data-test/test_removeHeaderEndings_order.csv";

import test_linkFields from "../data-test/test_linkFields.csv";
import test_linkFields_multi from "../data-test/test_linkFields_multi.csv";
import test_linkFields_missingtext from "../data-test/test_linkFields_missingtext.csv";
import test_linkFields_missingurl from "../data-test/test_linkFields_missingurl.csv";

import pix from "../data-pix/pix.csv";

const userImportedFileDatasetId = "imported"; // note: we export this variable

//////////////////////////////////////////////////////////////////////////

// Our list of built-in datasets, organized into collections.
// (In the UI, a dataset corresponds to a tab, and a collection
// corresponds to a menu item in either the main menu at top left,
// or the experimental menu at top right.)

const Datasets = {
  //////////////////////////////////////////////////////////////////////////
  // Research datasets comprise all the major known research repositories
  // at Autodesk, or at least the ones that can be readily exported as CSV.

  "Research Projects": {
    id: "res",
    datasets: [
      // Source: https://wiki.autodesk.com/display/RES/Research+Projects+-+All+-+Admin+view
      // We export the Admin View page because its set of fields is more complete.
      // Contact: Lars Jensen
      {
        id: "dxc",
        label: "DXC-XD",
        dataFile: dxc,
        info:
          "Source: <https://wiki.autodesk.com/display/RES/Research+Projects+-+All+-+Admin+view|Research projects> done by the <https://aware.autodesk.com/christie.mcallister|DXC XD Research team> (and some others). Exported Feb 4, 2025.",
        fieldsToExclude: ["Project Title"],
        fieldsToBold: ["Project"],
        hideEmptyFields: true,
        specialFormatting: {
          titleFields: "Project",
          dateFields: "Timeframe",
          descriptionFields: [
            "Abstract",
            "Key Research Questions",
            "Summary of Findings",
          ],
          authorFields: "Research Lead",
        },
      },

      // Source: https://wiki.autodesk.com/x/ee9cEg
      // Note: this one is exported directly.
      // Watch out for anomalies in the Contact field; it's very sensitive to delimiter irregularities.
      // Contact: Ryan Giesen
      {
        id: "cxa",
        label: "DXC-Analytics",
        dataFile: cxa,
        info:
          "Source: <https://wiki.autodesk.com/x/ee9cEg|Currently Supported Dashboards> created by the <https://aware.autodesk.com/ryan.giesen|DXC Analytics team>. Exported Feb 4, 2025.",
        specialFormatting: {
          titleFields: "Dashboard Name",
          authorFields: "Contact",
        },
        hideEmptyFields: true,
      },

      // source: https://wiki.autodesk.com/x/4AGhYg which is an edited snapshot of https://wiki.autodesk.com/x/2xmCVw
      // Contact: Jessica Raedler
      // Experimental dataset of Data Science roadmap export.
      {
        id: "ds",
        label: "DXC-DS",
        dataFile: ds_e,
        info:
          "Source: <https://wiki.autodesk.com/x/2xmCVw|FY23 Personalization Roadmap (Experiments tab)> from the <https://aware.autodesk.com/james.bradley|DXC Data Science team>. Exported Feb 4, 2025.",
        specialFormatting: {
          titleFields: "What We Launched",
          subtitleFields: "Helpful Documents",
          dateFields: ["FY23", "Launched On"],
          descriptionFields: "What We Learned",
          authorFields: "Key Contact",
        },
        hideEmptyFields: true,
      },

      // source: https://share.autodesk.com/sites/Hiveresearch
      // Contact: Kaethe Selkirk
      // Experimental dataset of Hive export.
      // Exported via copy/paste from the source, see text file in the data folder for more info.
      {
        id: "hive",
        label: "AEC/Hive",
        dataFile: hive,
        info:
          "Source: <https://share.autodesk.com/sites/Hiveresearch|Hive Hub> articles. Exported Jan 3, 2022.",
        hideEmptyFields: true,
        specialFormatting: {
          subtitleFields: "Link",
          dateFields: "Study End Date",
          authorFields: "Contact",
          descriptionFields: ["Description"], // hack; needed until we fix a minor bug with descriptionfields layout
        },
      },

      // Source: https://share.autodesk.com/:x:/s/ACS-UXResearch/Ebv0a5AOsPNNjNbuy-WRbzEB7LF9hUi8zCQrfhEsDAjUig
      // Contact: Christiana Lackner, Arian Saeedfar
      // The original Excel file (linked above) with several dozen rows of data, then around
      // a thousand blank rows, then a final row of data. Delete the blank rows before exporting.
      // Excel file covers activity through 2020-ish. Then ACS switched to Dovetail. Arian Saeedfar
      // manages that. Unclear how to represent this in Prism.
      {
        id: "acs",
        label: "ACS",
        dataFile: acs,
        info:
          "Source: <https://share.autodesk.com/:x:/s/ACS-UXResearch/Ebv0a5AOsPNNjNbuy-WRbzEB7LF9hUi8zCQrfhEsDAjUig|Research projects> from Autodesk Construction Solutions. Exported Oct 14, 2021.",
        hideEmptyFields: true,
        specialFormatting: {
          titleFields: "Title",
          subtitleFields: "Link to primary deliverable",
          dateFields: ["Year", "Date Completed"],
          descriptionFields: ["Summary"],
          authorFields: "Researcher/Designer",
        },
      },

      // Research projects that have been shared as part of the Research Spotlight series.
      // Source of truth: https://wiki.autodesk.com/display/ACSUXR/Research+Spotlight+Tracker
      // Contact: Alexandra Galanter
      {
        id: "spot",
        label: "ACS Spotlight",
        dataFile: spot,
        info:
          "Source: <https://wiki.autodesk.com/x/5juVM|Research Spotlight Tracker> projects that have been shared as part of the <https://wiki.autodesk.com/x/jrb5Lw|Research Spotlight> series. Exported Feb 4, 2025.",
        hideEmptyFields: true,
        fieldsToExclude: "Study title",
        specialFormatting: {
          dateFields: ["Study Completed"],
          authorFields: ["Team members", "Teammates"], // last record has odd "Team members" field; why?
          descriptionFields: [
            "Presentation",
            "Research Spotlight recording",
            "Synopsis of findings",
          ],
        },
      },

      // Source: https://wiki.autodesk.com/display/StrategicTechnologies/APS+Research+Projects+Tracker
      // Contact: Capra J'Neva
      // The source page has multiple child pages, with pagination and inconsistent schemas. So we created this
      // aggregator page for exporting: https://wiki.autodesk.com/display/RES/Research+Projects+-+APS
      {
        id: "aps",
        label: "PSET/APS",
        dataFile: pset,
        info:
          "Source: <https://wiki.autodesk.com/display/RES/Research+Projects+-+APS|APS research projects>, aggregated from the Platform Services & Emerging Technologies (PSET) <https://wiki.autodesk.com/pages/viewpage.action?pageId=591814185|Platform Experience XDR team>'s <https://wiki.autodesk.com/display/PRA/APS+Research+Projects+Tracker|APS Research Projects Tracker> page. Exported Feb 4, 2025.",
        hideEmptyFields: true, // this dataset has LOTS of empty fields
        removeHeaderEndings: [" *"],
        specialFormatting: {
          titleFields: "Title",
          dateFields: ["Completion Date", "Target Launch Date"],
          descriptionFields: ["Primary Research Question"],
          authorFields: [
            "Research Lead",
            "Researcher",
            "Role" /* hack; this messes up the avatars */,
          ],
          hideBlankAvatars: true, // "Role" causes spurious blanks
        },
      },

      // Source: https://wiki.autodesk.com/x/V5Y8Bg
      // Contact: Shari Nemerovski
      // AutoCAD User Research.
      {
        id: "acad",
        label: "AutoCAD",
        dataFile: acad,
        info:
          "Source: <https://wiki.autodesk.com/x/V5Y8Bg|AutoCAD User Research>. Exported Feb 4, 2025.",
        specialFormatting: {
          titleFields: "Project Name",
          authorFields: "Researcher + Contact",
          hideBlankAvatars: true, // "(Consultants)" causes spurious blank
        },
      },

      // source: https://wiki.autodesk.com/display/PDMSXD/Research+Report+Index
      // Contact: Melissa Anderson (was Peter Maxfield)
      // Note: Melissa fixed up the source page so we retired the aggregator page in June 2024.
      {
        id: "pdms",
        label: "PDMS",
        dataFile: pdms,
        info:
          "Source: <https://wiki.autodesk.com/display/PDMSXD/Research+Report+Index|Research Report Index>, from the <https://wiki.autodesk.com/display/PDMSXD/PDMS+XD+Home|Product Development & Manufacturing Solutions (PDMS) XD team>. Exported Feb 4, 2025.",
        hideEmptyFields: true,
        specialFormatting: {
          //titleFields: "Title", // not needed, can use default
          dateFields: ["Completion Date", "Target Launch Date"],
          authorFields: "Research Lead",
          descriptionFields: ["Research Method", "Primary Research Question"],
        },
      },

      // Source: https://wiki.autodesk.com/pages/viewpage.action?spaceKey=edu&title=Research+Archive
      // Contact: Satwik Polkampally
      {
        id: "aex",
        label: "AEX",
        dataFile: aex,
        info:
          "Source: <https://wiki.autodesk.com/x/h0csRg|Research Archive> of the Autodesk Education Experiences (AEX) team. Exported Nov 3, 2023.",
        specialFormatting: {
          titleFields: "Title",
          subtitleFields: "Project Title",
          dateFields: "Timeframe",
          descriptionFields: [
            "Abstract",
            "Key Research Questions",
            "Summary of Findings",
          ],
          authorFields: "Research Lead",
        },
      },

      // source: https://wiki.autodesk.com/display/RES/Research+at+Autodesk+University
      // Contact: Lars Jensen
      // Each of the in-person years 2017-2018 has a corresponding Research Roster page (or pages).
      // Export those, reconcile differences in record layouts, and combine to produce the overall CSV file.
      {
        id: "au",
        label: "AU",
        dataFile: au,
        info:
          "Source: <https://wiki.autodesk.com/display/RES/Research+at+Autodesk+University|Research projects> conducted at Autodesk University (in-person, 2017-2019). Updated Feb 10, 2020.",
        fieldsToBold: [
          "Workshop Title",
          "Study Topic",
          "Study Name/Topic",
          "Study Name",
        ],
        hideEmptyFields: true, // because of differing record layouts among source files
        specialFormatting: {
          titleFields: [
            "Workshop Title", // 2019 Workshop
            "Study Topic", // 2019 Idea Exchange
            "Study Name/Topic", // 2018
            "Study Name", // 2017
          ],
          subtitleFields: "Research Roster",
          dateFields: "Year",
          //watch for case sensitive
          descriptionFields: [
            "Workshop Description", // 2019 Workshop
            "Research Questions/Goals", // 2019 Idea Exchange
            "Research questions/goals", // 2018
            "Learning Goals", // 2017
          ],
          //if "Team Members" added, check number of avatars
          authorFields: [
            "Research Moderator", // 2019 Workshop
            "Study Moderator(s)", // 2019 Idea Exchange
            "Team Lead", // 2019 Idea Exchange
            "Study Lead", // 2018
            "Moderator", // 2017
          ],
        },
      },

      // Source: https://share.autodesk.com/sites/research
      // Contact: John Schmier
      {
        id: "octo",
        label: "Autodesk Research",
        dataFile: octo,
        info:
          "Source: <https://share.autodesk.com/sites/Research/SitePages/Autodesk-Research-Papers.aspx|Published Papers> from <https://research.autodesk.com|Autodesk Research> (formerly known as OCTO). Exported Dec 16, 2024.",
        hideEmptyFields: true,
        removeHeaderEndings: [" (comma delimited)"], // clean up Authors field
        specialFormatting: {
          //titleFields: "Title", // not needed, can use default
          subtitleFields: "Publication",
          dateFields: "Year Published",
          descriptionFields: ["Description", "Abstract"], // needed until tiny layout bug is fixed
          //authorFields: ["Authors", "Authors (comma delimitated)"], // not needed, can use default
          hideBlankAvatars: true, // most authors are non-ADSK
        },
        fieldsToExclude: ["Bibtex citation (optional)"], // potentially useful but mostly clutter at present
      },
            //WWM
     {
        id: "wwm",
        label: "MKT Optim",
        dataFile: wwm,
        info:
          "MKT Optim",
        hideEmptyFields: true,
        specialFormatting: {
          titleFields: "Name",
          subtitleFields: "Link to Results Deck",
          dateFields: "Quarter of Completion",
          authorFields: ["Analyst"], // note: currently crashes without 'authors'
          //descriptionFields: ["Description","Results","Decision","What Problem are we trying to solve?", "Hypothesis"],
        },
      },

    ],
  }, // end "Research Projects"

  //////////////////////////////////////////////////////////////////////////
  // Curated datasets of research that was not done at Autodesk.

  "External Research": {
    id: "ext",
    datasets: [
      // Source: https://share.autodesk.com/sites/dpe-xd/Shared%20Documents/Research/Research%20Library%20-%20External/CHI2018-Papers/
      // Contact: Judy Bayne
      {
        id: "chi",
        label: "CHI 2018 Papers",
        dataFile: chi,
        info:
          "These papers were submitted to the <https://chi2018.acm.org/|2018 ACM CHI Conference on Human Factors in Computing Systems>. Judy Bayne attended CHI 2018, has <https://share.autodesk.com/sites/dpe-xd/Shared%20Documents/Research/Research%20Library%20-%20External/CHI2018-Papers/|hosted the papers as PDFs in SharePoint>, and has <https://wiki.autodesk.com/display/RES/CHI+2018+Papers|summarized and categorized many of them in Confluence>.",
        fieldsToExclude: "Number",
        specialFormatting: {
          authorFields: "Author(s)",
          hideBlankAvatars: true,
        },
      },

      // Source: https://wiki.autodesk.com/pages/viewpage.action?spaceKey=RES&title=Social+Machines
      // Contact: Judy Bayne
      {
        id: "bots",
        label: "Social Machines",
        dataFile: bots,
        info:
          "Articles about the interactions between humans and machines; in particular, the business implications of the rise of conversational artificial intelligence. Curated by Judy Bayne, <https://wiki.autodesk.com/pages/viewpage.action?spaceKey=RES&title=Social+Machines|indexed on wiki> (exported Dec 5, 2021) and <https://share.autodesk.com/:f:/r/sites/dpe-xd/Shared%20Documents/Research/Research%20Library%20-%20External/Social-Machines|hosted as PDFs in SharePoint>.",
        specialFormatting: {
          hideBlankAvatars: true,
        },
      },

      // Source: https://wiki.autodesk.com/display/RES/Experience+Research
      // Contact: Judy Bayne
      {
        id: "expres",
        label: "Experience Research",
        dataFile: expres,
        info:
          "A <https://wiki.autodesk.com/x/OjilRQ|collection of articles and videos> about the experience research practice, curated by Judy Bayne. The full collection contains more titles; to learn more, contact Judy Bayne.",
        specialFormatting: {
          hideBlankAvatars: true,
        },
      },

      // Cognitive biases. An experiment with Christie. See wiki page at https://wiki.autodesk.com/display/DPEXD/Cognitive+Biases
      {
        id: "bias",
        label: "Cognitive Biases",
        dataFile: bias,
        info:
          "<https://wiki.autodesk.com/x/x5AiRg|Cognitive biases> (Wiki) from a <https://app.mural.co/invitation/mural/dpe0701/1612198956303?sender=larsjensen&key=cd0dbd5d-54b8-47aa-a46a-49fca1459fba|Mural> curated by Christie McAllister. Exported Jan 19, 2022.",
        cardView: {
          title: "Name",
          body: "Description",
          link: "Example",
          imageDir: "./data-bias/images/",
          imageExt: ".jpg",
        },
      },
    ],
  }, // end "External Research"

  //////////////////////////////////////////////////////////////////////////
  // General-interest datasets are those that have a potentially wide
  // audience at Autodesk, and require no specialized knowledge to browse.

  "General Reference": {
    id: "ref",
    datasets: [
      // source: http://autodesk.com/products
      // Contact: Lars Jensen
      // Scroll down to see A-Z list, then patiently harvest URLs and descriptions from there.
      // Do light editing on some entries for consistency and descriptiveness for Autodesk staff.
      {
        id: "prod",
        label: "Autodesk Products",
        dataFile: prod,
        info:
          "Collected from A-Z list at bottom of <http://autodesk.com/products|Autodesk Products> page. Updated July 26, 2021.",
        hideFieldNames: true,
        cardView: {
          title: "Name",
          body: "Description",
          link: "URL",
          imageDir: "./data-products/images/",
          imageExt: ".png",
        },
      },

      // source: https://wiki.autodesk.com/display/IDOC/Alex+--+the+Autodesk+Lexicon
      // Contact: Lars Jensen
      // Export process is documented at https://git.autodesk.com/jensenl/lookup
      //
      // This is an example of importing multiple files with the same schema,
      // which saves several steps combining the files manually. It is also
      // an example of importing tab-delimited text files instead of CSV files.
      // The manual steps would be:
      // - to each file, append a column identifying which dictionary the file represents
      // - concatenate the files
      // - sort by term
      // - add column headers
      // - replace all ":edit:" with "[wiki]" (or similar) <-- not yet automated
      // - replace all "https://api.wiki.autodesk.com" with "https://wiki.autodesk.com" <-- not yet automated
      {
        id: "alex",
        label: "Alex - Autodesk Lexicon",
        dataFile: [
          {
            appendColumn: {
              header: "Dictionary",
              value: "Autodesk terms",
            },
            file: alex_adsk,
          },
          {
            appendColumn: {
              header: "Dictionary",
              value: "Industry terms",
            },
            file: alex_industry,
          },
          {
            appendColumn: {
              header: "Dictionary",
              value: "TAD",
            },
            file: alex_tad,
          },
        ],
        delimiter: "\t",
        headers: ["Term", "Definition", "Dictionary"],
        sortBy: ["Term"],
        fieldsToBold: ["Term"],
        info:
          "<http://autode.sk/alex|Alex> is our wiki-based corporate glossary. Exported Mar 21, 2023.",
      },

      // // source: https://wiki.autodesk.com/display/IDOC/Alex+--+the+Autodesk+Lexicon
      // // Contact: Lars Jensen
      // // Export process is documented at https://git.autodesk.com/jensenl/lookup
      // // This is an obsolete method of importing Alex as a single file. After export:
      // // 1) add a Dictionary column
      // // 2) concatenate the files, add a header
      // // 3) sort by Term
      // // 4) replace all ":edit:" with "[wiki]" (or similar)
      // // 5) replace all "https://api.wiki.autodesk.com" with "https://wiki.autodesk.com"
      // {
      //   hidden: true, // hide as a prelude to removal
      //   id: "alex_single",
      //   label: "Alex (single file)",
      //   dataFile: alex,
      //   delimiter: ",",
      //   fieldsToBold: ["Term"],
      //   info:
      //     "<http://autode.sk/alex|Alex> is our wiki-based corporate glossary. Exported Mar 21, 2023. (Single-file version)",
      // },
    ],
  }, // end "General Reference"

  //////////////////////////////////////////////////////////////////////////
  // Room-finding datasets. These are currently the only datasets that
  // support the concepts of an individual item selection, and a detail
  // area to show more detail about that selection. They also have
  // heavily customized field displays (currently in datasetManager.js).
  // At some point this should all become more generalized and modular.

  "Conference Rooms": {
    id: "maps",
    datasets: [
      // Source: https://share.autodesk.com/CREFTS/locations/Pages/floor-maps.aspx
      // Contact: Lars Jensen
      // Maps are created in Sketch (see ./misc folder), data augmented by walking around a lot.
      {
        id: "sf",
        label: "San Francisco (1 Market)",
        dataFile: sf,
        fieldsToSearch: [
          "Name",
          "Number",
          "Building",
          "Notes",
          "Description",
          "Namesake",
          "URL",
        ],
        info:
          "<https://share.autodesk.com/CREFTS/locations/Pages/floor-maps.aspx|Conference rooms> in the One Market Plaza complex in San Francisco CA, including Landmark, Steuart, and Spear towers. Updated Mar 3, 2020.",
        hideItemSeparator: true,
        hasCustomStyling: true,
        hasItemDetail: true,
        imageDir: "./data-sf-onemarket/images/",
        defaultImage: "./data-sf-onemarket/images/sf.png",
      },

      // Source: https://share.autodesk.com/CREFTS/locations/Pages/floor-maps.aspx
      // Contact: Lars Jensen
      {
        id: "pdx",
        label: "Portland OR",
        dataFile: pdx,
        fieldsToSearch: [
          "Name",
          "Number",
          "Building",
          "Notes",
          "Description",
          "Namesake",
          "URL",
        ],
        info:
          "<https://share.autodesk.com/CREFTS/locations/Pages/floor-maps.aspx|Conference rooms> in Portland OR. Updated Jan 19, 2020.",
        hideItemSeparator: true,
        hasCustomStyling: true,
        hasItemDetail: true,
        imageDir: "./data-pdx/images/",
        defaultImage: "./data-pdx/images/pdx.jpg",
      },
    ],
  }, // end "Conference Rooms"

  //////////////////////////////////////////////////////////////////////////
  // Datasets for testing the basic ability to read and display data.
  // These are meant to be mostly straightforward cases to help diagnose
  // display problems.

  "# Test Basic Capabilities": {
    id: "test_basic_capabilities",
    datasets: [
      {
        id: "test_basic_loading",
        label: "Loading",
        dataFile: test_basic_loading,
        info: "Tests basic file loading, parsing, and display.",
        //hidden: true, // hack; not testing this yet, but we should somehow
      },

      {
        id: "test_basic_formatting",
        label: "Formatting",
        dataFile: test_basic_formatting,
        info:
          "Tests standard formatting. (Tests of specialFormatting are elsewhere.)",
        hideEmptyFields: true,
        fieldsToExclude: ["Excluded"],
        fieldsToItalicize: ["Name"],
      },

      {
        id: "test_basic_linking",
        label: "Linking",
        dataFile: test_basic_linking,
        info: "Tests our auto-linking capability.",
        fieldsToBold: ["Expected"], // for readability
      },

      {
        id: "test_basic_headers",
        label: "Headers",
        dataFile: test_basic_headers,
        info: "Tests for the ability to handle duplicate headers.",
        hideEmptyFields: true, // so Expected will appear at the top, not cluttering up the records
        fieldsToBold: [], // auto-bolding is distracting here
      },
    ],
  }, // end "# Test Basic Capabilities"

  //////////////////////////////////////////////////////////////////////////
  // Datasets in this collection exercise the specialFormatting capability
  // (previously known as DataCard view).
  //
  // Defaults per field block:
  //   titleFields: ["title", "name"],
  //   subtitleFields: ["subtitle"],
  //   authorFields: ["author", "authors"],
  //   dateFields: ["date", "year", "timeframe"],
  //   descriptionFields: ["description", "abstract"],

  "# Test Special Formatting": {
    id: "test_special_formatting",
    datasets: [
      {
        id: "test_specialFormatting_none",
        label: "None",
        dataFile: test_specialFormatting_none,
        info:
          'To avoid special formatting, omit the specialFormatting property. All fields appear with standard formatting in the "other fields" section.',
        hideEmptyFields: true,
      },

      {
        id: "test_specialFormatting_default",
        label: "Default",
        dataFile: test_specialFormatting_default,
        info:
          "To apply default special formatting to all field blocks, include an empty specialFormatting property.",
        hideEmptyFields: true,
        specialFormatting: {},
      },

      {
        id: "test_specialFormatting_multi",
        label: "Multiple fields",
        dataFile: test_specialFormatting_multi,
        info:
          "All specialFormatting field blocks should be able to specify multiple fields.",
        hideEmptyFields: true, // because of differing record layouts among source files
        specialFormatting: {
          titleFields: ["Title1", "Title2"],
          subtitleFields: ["Subtitle1", "Subtitle2"],
          dateFields: ["Date1", "Date2"],
          descriptionFields: ["Description1", "Description2"],
          authorFields: ["Author1", "Author2"],
        },
      },

      {
        id: "test_specialFormatting_override",
        label: "Override defaults",
        dataFile: test_specialFormatting_override,
        info:
          "Any field block that is specified should override the defaults for that block.",
        hideEmptyFields: true,
        specialFormatting: {
          // titleFields: ["title", "name"],
          // subtitleFields: ["subtitle"],
          authorFields: ["author", "author2"],
          // dateFields: ["date", "year", "timeframe"],
          // descriptionFields: ["description", "abstract"],
        },
      },

      {
        id: "test_specialFormatting_suppress",
        label: "Suppress per block",
        dataFile: test_specialFormatting_suppress,
        info:
          'To avoid special formatting for a particular field block, list it as empty. The default field list is ignored for that block, and default fields that are not accounted for in any other block appear with standard formatting in the "other fields" section.',
        hideEmptyFields: true,
        specialFormatting: {
          titleFields: [],
          subtitleFields: [],
          authorFields: [],
          dateFields: [],
          descriptionFields: [],
        },
      },

      {
        id: "test_specialFormatting_avatars",
        label: "Avatars",
        dataFile: test_specialFormatting_avatars,
        info: "Tests avatar display (see also unit tests for this)",
        // hack; also need to test maxAvatarCount
        hideEmptyFields: true,
        specialFormatting: {
          titleFields: "Test",
          dateFields: ["Time", "Time2"],
          authorFields: ["People", "People2"],
          descriptionFields: ["Expected"],
        },
      },

      {
        id: "test_specialFormatting_blanks",
        label: "Blanks",
        dataFile: test_specialFormatting_blanks,
        info:
          "Tests special formatting with various combinations of blank fields.",
        specialFormatting: {
          hideBlankAvatars: true, // default is false, which is tested in test_specialFormatting_avatars
        },
      },
    ],
  }, // end "# Test Special Formatting"

  //////////////////////////////////////////////////////////////////////////
  // This collection exercises dataset capabilities that modify the
  // dataset before presenting it to the user.

  "# Test Data Modifications": {
    id: "test_data_modifications",
    datasets: [
      // This tab tests removeHeaderEndings:
      // - multiple endings specified
      // - successive endings removed from the same field
      // - null ending string (ignored)
      // - special characters ('*' in this case)
      {
        id: "test_removeHeaderEndings",
        label: "removeHeaderEndings",
        dataFile: test_removeHeaderEndings,
        removeHeaderEndings: ["()", "2", "*", " (comma delimitated)", ""],
        info:
          "Tests the removeHeaderEndings property. Reference: https://wiki.autodesk.com/display/~jensenl/Prism+-+Dataset+Capabilities",
      },

      {
        id: "test_removeHeaderEndings_order",
        label: "removeHeaderEndings: order",
        dataFile: test_removeHeaderEndings_order,
        info:
          "Tests order of operation of removeHeaderEndings vs specialFormatting",
        removeHeaderEndings: ["*"],
        specialFormatting: {
          //authorFields: ["Author", "Author*"], // if this line is enabled, it works
        },
      },

      // These tabs test the linkFields capability described here:
      // https://wiki.autodesk.com/display/~jensenl/Prism+-+Dataset+Capabilities

      {
        id: "test_linkFields",
        label: "linkFields",
        dataFile: test_linkFields,
        info:
          "Tests basic functionality of the linkFields property. Reference: https://wiki.autodesk.com/display/~jensenl/Prism+-+Dataset+Capabilities",
        linkFields: [
          {
            text: "Text",
            url: "URL",
          },
        ],
      },

      {
        id: "test_linkFields_multi",
        label: "linkFields: multi fields",
        dataFile: test_linkFields_multi,
        info:
          "Tests the linkFields property with multiple fields. Reference: https://wiki.autodesk.com/display/~jensenl/Prism+-+Dataset+Capabilities",
        linkFields: [
          {
            text: "Text",
            url: "URL",
          },
        ],
      },

      {
        id: "test_linkFields_missingtext",
        label: "linkFields: missing text field",
        dataFile: test_linkFields_missingtext,
        info:
          "Tests the linkFields property with missing text field. Reference: https://wiki.autodesk.com/display/~jensenl/Prism+-+Dataset+Capabilities",
        linkFields: [
          {
            text: "Text",
            url: "URL",
          },
        ],
      },

      {
        id: "test_linkFields_missingurl",
        label: "linkFields: missing URL field",
        dataFile: test_linkFields_missingurl,
        info:
          "Tests the linkFields property with missing URL field. Reference: https://wiki.autodesk.com/display/~jensenl/Prism+-+Dataset+Capabilities",
        linkFields: [
          {
            text: "Text",
            url: "URL",
          },
        ],
      },
    ],
  }, // end '# Test Data Modifications'

  //////////////////////////////////////////////////////////////////////////
  // This collection exercises various input file formats beyond just CSV.

  "# Test Input Formats": {
    id: "test_input_formats",
    datasets: [
      // This tab tests Forge in JSON format.
      {
        id: "pset-json",
        label: "PSET/APS - JSON",
        dataFile: psetJson,
        isJson: true,
        info:
          "<https://wiki.autodesk.com/display/RES/Research+Projects+-+Forge|Forge research projects>, aggregated from the Platform Services & Emerging Technologies (PSET) <https://wiki.autodesk.com/pages/viewpage.action?pageId=591814185|Platform Experience XDR team>'s <https://wiki.autodesk.com/display/PE/Forge+Research+Projects+Tracker|Forge Research Projects Tracker> page. Exported Aug 5, 2022.",
        hideEmptyFields: true, // this dataset has LOTS of empty fields
        removeHeaderEndings: [" *"],
        specialFormatting: {
          titleFields: "Title",
          dateFields: ["Completion Date", "Target Launch Date"],
          descriptionFields: ["Primary Research Question"],
          authorFields: [
            "Research Lead",
            "Researcher",
            "Role",
          ] /* hack; removed Role b/c it messes up the avatars */,
        },
      },

      // This tab is the same as Research Projects > Forge.
      {
        id: "pset",
        label: "PSET/APS",
        dataFile: pset,
        info:
          "<https://wiki.autodesk.com/display/RES/Research+Projects+-+Forge|Forge research projects>, aggregated from the Platform Services & Emerging Technologies (PSET) <https://wiki.autodesk.com/pages/viewpage.action?pageId=591814185|Platform Experience XDR team>'s <https://wiki.autodesk.com/display/PE/Forge+Research+Projects+Tracker|Forge Research Projects Tracker> page. Exported Aug 5, 2022.",
        hideEmptyFields: true, // this dataset has LOTS of empty fields
        removeHeaderEndings: [" *"],
        specialFormatting: {
          titleFields: "Title",
          dateFields: ["Completion Date", "Target Launch Date"],
          descriptionFields: ["Primary Research Question"],
          authorFields: [
            "Research Lead",
            "Researcher",
            "Role",
          ] /* hack; removed Role b/c it messes up the avatars */,
        },
      },
    ],
  }, // end '# Test Input Formats'

  //////////////////////////////////////////////////////////////////////////
  // Datasets that we would like to publish, but that are not ready yet.

  "# In Development": {
    id: "dev",
    datasets: [
      // source: https://wiki.autodesk.com/x/zPHyaQ
      // Persona frameworks (in development). Contact: Lars Jensen
      {
        id: "personas",
        label: "Personas",
        dataFile: personas,
        info:
          "<https://wiki.autodesk.com/x/zPHyaQ|Persona frameworks> at Autodesk. Work in progress. Updated Feb 14, 2024.",
        hideEmptyFields: true,
        specialFormatting: {
          titleFields: ["Catalog Page"],
          subtitleFields: ["Framework"],
          authorFields: ["Origins"],
          descriptionFields: [
            "Description",
            "Focus",
            "Intentions",
            "Components",
            "Personas",
          ],
        },
      },

      // Autodesk conducted or commissioned research for the CSO Success Portfolio team.
      // https://wiki.autodesk.com/pages/viewpage.action?spaceKey=SPL&title=Customer+Success+Design+Research+Library
      // Contact: Jean-Philippe Roch
      {
        id: "cso",
        label: "CSO Research",
        dataFile: cso,
        info:
          "<https://wiki.autodesk.com/pages/viewpage.action?spaceKey=SPL&title=Customer+Success+Design+Research+Library|Customer Success Design Research Library>. Exported Jul 6, 2022.",
        hideEmptyFields: true,
        specialFormatting: {
          //titleFields: "Test",
          dateFields: ["Date Research Conducted"],
          //authorFields: ["People", "People2"], // note: currently crashes without 'authors'
          descriptionFields: ["Primary Research Question", "What We Learned"],
        },
      },

      //WWM
     {
        id: "wwm",
        label: "MKT Optim",
        dataFile: wwm,
        info:
          "MKT Optim",
        hideEmptyFields: true,
        specialFormatting: {
          titleFields: "Name",
          subtitleFields: "Link to Results Deck",
          dateFields: "Quarter of Completion",
          authorFields: ["Analyst"], // note: currently crashes without 'authors'
          //descriptionFields: ["Description","Results","Decision","What Problem are we trying to solve?", "Hypothesis"],
        },
      },

      // Research conducted by/for Entertainment & Media Solutions, some while back.
      // 53 records. Some are in HIVE which is retired. Some are in John Schrag's
      // OneDrive (he is also retired). Most URLs need access to be granted.
      {
        id: "ems",
        label: "EMS Research",
        dataFile: ems,
        info: "Work in progress",
        //hideEmptyFields: true,
        // linkFields: [
        //   {
        //     text: "Study",
        //     url: "URL",
        //   },
        // ],
        specialFormatting: {
          titleFields: "Study",
          subtitleFields: "URL",
          // dateFields: ["Date Research Conducted"],
          // authorFields: ["People", "People2"], // note: currently crashes without 'authors'
          // descriptionFields: ["Primary Research Question", "What We Learned"],
        },
      },

      // Source: https://wiki.autodesk.com/display/RES/Market+Research
      // Contact: Lars Jensen
      {
        id: "miscres",
        label: "Misc",
        dataFile: miscres,
        info:
          "A <https://wiki.autodesk.com/x/PF0sRg|collection of research material> (wiki) generated externally and not in other categories. Contributions are welcome.",
        specialFormatting: {
          titleFields: "Title",
          dateFields: "Date",
          authorFields: ["Contributor"],
          descriptionFields: "Abstract",
        },
      },

      // source: https://share.autodesk.com/sites/Hiveresearch
      // Contact: Kaethe Selkirk
      // Experimental dataset of Hive export.
      // Exported via copy/paste from the source, see text file in the data folder for more info.
      {
        id: "hive-old",
        label: "Hive (old)",
        dataFile: hive_old,
        info:
          "Listing of all posts from the old <https://hive.autodesk.com/|Hive WordPress site>, for comparison to the <https://share.autodesk.com/sites/Hiveresearch|new Hive SharePoint site>. Note that this lists the poster and post date, not the author and project date. Exported Jan 31, 2022.",
        specialFormatting: {
          titleFields: "Title",
          subtitleFields: "URL",
          dateFields: "Date",
          descriptionFields: "Tags",
          authorFields: "Poster",
        },
      },
    ],
  }, // end "# In Development"

  //////////////////////////////////////////////////////////////////////////
  // Datasets for experimentation. If these are not visible in the menu,
  // access them by using the "d=id" parameter, where "id" is the dataset id.

  "# Experiments": {
    id: "x",
    datasets: [
      // This is a good general dataset, but it really points out the need for a grid view, with sortable columns.
      // Also a great use case for calculated fields (exchange rate and performance) and groupBy capability.
      // Source: https://autodesk.slack.com/archives/C0KBT3859/p1608085187346800 (#adsk-general, Dec 15, 2020)
      // Contact: Lars Jensen
      {
        id: "applause",
        label: "Applause Gift Cards",
        dataFile: applause,
        info:
          "Applause gift card redemption values, sorted by the 'exchange rate' to dollars, with highest first. <https://autodesk.slack.com/archives/C0KBT3859/p1608085187346800|Data> collected from 'Shop All > Explore More > All Gift Cards' by Lars Jensen Oct 21, 2020.",
        fieldsToBold: ["Brand", "Performance"],
      },

      // Decison-making is a potential collection that attempts to document
      // decisions made using the Autodesk Decision-Making Framework (DMF).
      //
      // This collection is currently very incomplete; there are a lot of small
      // to medium size datasets of this type. Search the #empower-decision-makers
      // Slack channel to find a bunch of them.

      // source: https://app.asana.com/0/1200468324616017/list
      // Contact: Elise Youmans
      // CSV Export of Asana task. The DMF "template" is in the Notes field.
      // Has a template record, which points out the need for "recordsToExclude".
      // Several Asana task fields are not of interest and are therefore excluded.

      {
        id: "dmf-rwx",
        label: "RWX Decisions",
        dataFile: dmf_rwx,
        info:
          "<https://app.asana.com/0/1200468324616017/list|Rosetta decision making (Asana)>. Exported Jul 31, 2021.",
        fieldsToExclude: [
          "Task ID",
          "Created At",
          "Completed At",
          "Assignee Email",
          "Start Date",
          "Due Date",
          "Parent Task",
        ],
      },

      {
        id: "dmf-acs",
        label: "ACS XA Decisions",
        dataFile: dmf_acs,
        info:
          "<https://wiki.autodesk.com/x/uzSPLQ|ACS XA Decisions>. Exported Jul 31, 2021.",
        fieldsToBold: ["Architecture Decisions"],
      },

      // source: https://wiki.autodesk.com/display/RES/Research+at+Autodesk+University
      // Contact: Kaethe Selkirk
      // Experimental dataset of AU Impact Cards (from 2019).
      // Lars copied the content from Kaethe's final deck to the source page.
      {
        id: "au-impact",
        label: "Impact@AU",
        dataFile: au_impact,
        info:
          "<https://wiki.autodesk.com/display/RES/Research+Projects+-+AU+Impact|AU Impact Cards> from Autodesk University (in-person, 2019). Added Oct 19, 2021.",
        hideEmptyFields: true,
        specialFormatting: {
          titleFields: "Title",
          subtitleFields: "Subtitle",
          dateFields: "Year",
          descriptionFields: [
            "Description",
            "We Studied",
            "We learned",
            "We built",
            "Next",
          ],
          authorFields: "Person",
        },
        //hidden: true, // is this one worthy of top-level promotion?
      },

      // Source: https://wiki.autodesk.com/x/Tmi6Qw
      // Contact: Arian Saeedfar
      // Experiment with quick export of ACS data hosted in Dovetail and wiki.
      // So far, just a few records from a proof-of-concept personal wiki page.
      {
        id: "acsdt",
        label: "ACS (Dovetail)",
        dataFile: acs_dt,
        info:
          "<https://wiki.autodesk.com/x/Tmi6Qw|Research projects> from Autodesk Construction Solutions (ACS) that are mainly hosted in Dovetail, but reflected in Confluence as well. Exported Dec 1, 2021.",
        hideEmptyFields: true,
        specialFormatting: {
          titleFields: "Title",
          dateFields: "Time period",
          descriptionFields: "Goal",
          authorFields: "Owners",
        },
      },

      // A fun dataset containing some staff travel pictures and the stories behind them.
      // Source: PowerPoint deck that Yulia Borova compiled. Not sure if it has a permanent link.
      // Work in progress. (But sometimes useful in testing image handling.)
      // Contact: Lars Jensen
      {
        id: "pix",
        label: "Travel Pix",
        dataFile: pix,
        info:
          "From '_'My Picture, My Story'_' DPE team event, organized by Yulia Borova, August 2021.",
        cardView: {
          title: "Name",
          body: "Story",
          link: "URL",
          imageDir: "./data-pix/images/",
          imageExt: ".jpg",
        },
      },

      // Source: same as 'pdms'.
      {
        id: "pdms-card",
        label: "PDMS Cards",
        dataFile: pdms,
        info: "Experiment with Card View for PDMS data.",
        cardView: {
          title: "Title",
          body: "Primary Research Question",
          link: "Research Lead",
          // Fields from list view:
          //   dateFields: "Target Launch Date",
          //   authorFields: "Research Lead",
          //   descriptionFields: "Research Method",
        },
      },

      // This might be a great PRISM tab, but the "Tip" field that contains the main description has a huge amount of extraneous HTML markup,
      // and breaks our SuperParser. It breaks even if we list it in fieldsToExclude, so that should be more robust too. But it does not break
      // if there is search text present! So leaving this in for further investigatino, even though it will crash if we go here with no search text.
      //
      // Source: https://wiki.autodesk.com/display/RES/Market+Research
      // Contact: Lars Jensen
      {
        id: "tips",
        label: "ESE Tips",
        dataFile: tips,
        info:
          "A <https://share.autodesk.com/sites/DES/Lists/Submit%20Your%20Tip/AllItems.aspx?viewpath=%2Fsites%2FDES%2FLists%2FSubmit%20Your%20Tip%2FAllItems%2Easpx&sortField=Date%5Fx0020%5Fsubmitted&isAscending=false&viewid=ae550540%2D232b%2D43ce%2Db59d%2D496b3ed48015|collection of tips and tricks from ESE/DES/IT>. Submit your own tip for Applause points! Exported Jan 31, 2022",
        fieldsToBold: "Tip title", // hack; this bolds "Tip" instead -- fix!
        fieldsToExclude: "Tip", // hack; this bolds "Tip" instead -- fix!
      },
    ],
  }, // end "# Experiments"

  //////////////////////////////////////////////////////////////////////////
  // Datasets for experimentation with a geographic focus.
  // Source: https://www.disabled-world.com/calculators-charts/iso-codes.php

  "# Experiments - Geo": {
    id: "geo",
    datasets: [
      // Various combinations of country names, abbreviations, and dialing codes.
      // For experimenting with Digital HIG component development.
      {
        id: "dial",
        label: "Dialing+A2+A3",
        dataFile: dial,
        info:
          "Country codes: Name, ISO-ALPHA-2, ISO-ALPHA-3, calling code. For experimenting with various kinds of filtering. Source: https://www.disabled-world.com/calculators-charts/iso-codes.php",
      },
      {
        id: "dial2",
        label: "Dialing+A2",
        dataFile: dial2,
        info:
          "Country codes: Name, ISO-ALPHA-2, ISO-ALPHA-3, calling code. For experimenting with various kinds of filtering. Source: https://www.disabled-world.com/calculators-charts/iso-codes.php",
      },
      {
        id: "dial3",
        label: "Dialing+A3",
        dataFile: dial3,
        info:
          "Country codes: Name, ISO-ALPHA-2, ISO-ALPHA-3, calling code. For experimenting with various kinds of filtering. Source: https://www.disabled-world.com/calculators-charts/iso-codes.php",
      },
      // avoid clutter until we need this
      // {
      //   id: "dialall",
      //   label: "Dialing+A2+A3+M49",
      //   dataFile: dialall,
      //   info:
      //     "Country codes: Name, ISO-ALPHA-2, ISO-ALPHA-3, calling code. For experimenting with various kinds of filtering. Source: https://www.disabled-world.com/calculators-charts/iso-codes.php",
      // },
    ],
  }, // end "# Experiments - Geo"

  //////////////////////////////////////////////////////////////////////////
  // User-imported dataset. Don't remove this because other code relies on it.

  "# Imported": {
    id: "imp",
    datasets: [
      {
        id: userImportedFileDatasetId,
        label: "Imported CSV",
        dataFile: null,
        info:
          "User-imported data. The import is local to this browser tab, and can't be seen by other Prism users. " +
          " It will be lost if the tab is closed or reloaded, or if another file is imported.",
        //hidden: true,
      },
    ],
  }, // end "# Imported"
}; // end Datasets

export { Datasets, userImportedFileDatasetId };
